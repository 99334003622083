import React from 'react'

function BottomFooter() {
  return (
    <>
      <div className="container pb-4">
        <div className="row mt-5">
          <div className="col-12 d-flex justify-content-between align-items-center flex-wrap margin_bottom">
            <div className='margin_movil'>
              <div className='d-flex mb-3'>
                <a target="_blank" href="https://www.facebook.com/TerrazasDelMolino" class="text-center nav-link bg-gold text-light fs-5 rounded-circle d-flex justify-content-center align-items-center p-0 icons-size">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a target="_blank" href="https://www.instagram.com/terrazasdelmolino/" class="text-center nav-link bg-gold text-light fs-5 rounded-circle d-flex justify-content-center align-items-center p-0 icons-size ms-2">
                  <i class="fa-brands fa-instagram"></i>
                </a>
                <a target="_blank" href="https://wa.me/524778462543" class="text-center nav-link bg-gold text-light fs-5 rounded-circle d-flex justify-content-center align-items-center p-0 icons-size ms-2">
                  <i class="fa-brands fa-whatsapp"></i>
                </a>
              </div>
              <a href="tel:4777811119" className='text-decoration-none'>
                <p className='text-gold fw-bold fs-3'>T. (477) 781-1119</p>
              </a>
            </div>
            <div>
              <div className='m_icon'><img className='img-fluid my-3 m-4 logo_espacio logo_desarrollos_180' src="images/firmas/logo-grupo-dmi.svg" alt="" /></div>
              <div className='m_icon'><img className='img-fluid my-3 logo_espacio logo_desarrollos_180' src="images/firmas/logo-promovibra.svg" alt="" /></div>
              <div className='m_icon'><img className='img-fluid my-3 logo_espacio logo_desarrollos_180' src="images/firmas/logo-activo.svg" alt="" /></div>
            </div>
          </div>
        </div>
      </div>
      <footer className='container-fluid bg-dark py-4'>
          <div className='container text-center'>
              <h5 className='text-light m-0'>© TERRAZAS DEL MOLINO 2023</h5>
          </div>
      </footer>
    </>
  )
}

export default BottomFooter