import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function TopNavBar() {
  return (
    <Navbar bg="dark" data-bs-theme="dark" collapseOnSelect expand="lg" className="py-2 position-fixed top-0 start-0 w-100 h-85px">
      <Container>
        <Navbar.Brand href="/"><img loading='lazy' src="images/all/Logo_TDM.png" className='margin_logo' alt="Terrazas Del Molino" height={60} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto bg-dark d-flex justify-content-center">
            <Nav.Link className='text-gold text-center fw-bold' href="/">HOME</Nav.Link>
            <Nav.Link className='text-gold text-center fw-bold' href="/amenidades">AMENIDADES</Nav.Link>
            <Nav.Link className='text-gold text-center fw-bold' href="/departamentos">DEPARTAMENTOS</Nav.Link>
            <Nav.Link className='text-gold text-center fw-bold' href="/desarrollador">DESARROLLADOR</Nav.Link>
            <Nav.Link className='text-gold text-center fw-bold' href="/contacto">AGENDA TU VISITA</Nav.Link>
          </Nav>
          <Nav className="ms-auto bg-dark d-flex flex-row justify-content-around">
            <Nav.Link className='text-gold text-center' target='_blank' href="https://bit.ly/3Qdg1U7"><i class="fa-brands fa-whatsapp"></i></Nav.Link>
            <Nav.Link className='text-gold text-center' target='_blank' href="https://www.facebook.com/TerrazasDelMolino"><i class="fa-brands fa-square-facebook"></i></Nav.Link>
            <Nav.Link className='text-gold text-center' target='_blank' href="https://www.instagram.com/terrazasdelmolino/"><i class="fa-brands fa-instagram"></i></Nav.Link>
            <Nav.Link className='text-gold text-center' target='_blank' href="tel:4777811119"><i class="fa-solid fa-phone"></i></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default TopNavBar