import React from 'react'
import BottomFooter from '../components/BottomFooter'
import TopNavBar from '../components/TopNavBar'

function AvisoPrivacidad() {
  return (
    <>
    <TopNavBar/>
        <div className="container m-85px pt-5">
            <div className="row">
                <div className="col-12">
                    <svg
                        className='mb-5'
                        style={{height:'100px'}}
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 126.4 104.1"
                        version="1.1"
                        viewBox="0 0 126.4 104.1"
                        xmlSpace="preserve"
                        >
                        <path
                            d="M0 0l36.7 36.2v67.9h14.5V50.5l29.4 29h5.7L5.7 0z"
                            className="st0"
                        ></path>
                        <path
                            d="M115.8 0L79.1 36.2 42.4 0h-5.7l39.5 39 10.2 10.1 36-35.6v90.6h4V0z"
                            className="st0"
                        ></path>
                    </svg>
                    <h1>AVISO DE PRIVACIDAD INTEGRAL PARA CLIENTES Y VISITANTES</h1>
                    <p>En cumplimiento a las diversas disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP o la Ley), y la normatividad que de ella deriva; Bienes Raíces El Molino S.A. de C.V. (en lo subsecuente "La Empresa" o "Terrazas del Molino", indistintamente) con domicilio en Avenida Acueducto número 4851; piso 17; Colonia. Real Acueducto; Zapopan, Jalisco; Código Postal 45116; pone a su disposición el presente Aviso de Privacidad, manifestando que es la Responsable del tratamiento de los siguientes datos personales:
                    </p>
                    <p><strong>1. DATOS PERSONALES RECABADOS </strong></p>
                    <ul>
                        <li>Datos de identificación. </li>
                        <li>Datos de contacto.</li>
                        <li>Datos laborales.</li>
                        <li>Datos sobre características físicas. </li>
                        <li>Datos patrimoniales o financieros.</li>
                    </ul>
                    <p><strong>1.1 DATOS PERSONALES SENSIBLES</strong></p>
                    <ul>
                        <li>Terrazas del Molino no recaba datos personales sensibles de sus clientes y visitantes</li>
                    </ul>
                    <p>En caso de que desee conocer el listado detallado de los datos personales recabados en las categorías previamente referidas; usted podrá contactar a nuestro Oficial de Privacidad y ejercer su derecho de Acceso, empleando el mecanismo previsto en el apartado denominado "Medios y procedimiento para el ejercicio de los derechos ARCO" de este Aviso de Privacidad.</p>

                    <p>Resulta importante señalar que los datos personales que recabamos, únicamente los obtenemos cuando usted los proporciona directamente al solicitar o contratar alguno de nuestros servicios; cuando contrata algún financiamiento de entidades financieras, quienes compartirán con nosotros su información, o a través de nuestras redes sociales.</p>
                    
                    <p>Los datos personales previamente referidos, serán utilizados por La Empresa para llevar a cabo las siguientes actividades –denominadas finalidades en la LFPDPPP–:</p>

                    <p><strong>2. FINALIDADES </strong></p>
                    <p><strong>2.1 FINALIDADES QUE SE ORIGINAN CON LOS SERVICIOS PROPORCIONADOS POR LA EMPRESA (originadas y necesarias para el cumplimiento de la relación jurídica) </strong></p>
                    <ul>
                        <li>Identificar al usuario al que se le proporciona alguno de nuestros servicios.</li>
                        <li>Elaborar cotizaciones de los servicios ofertados por La Empresa.</li>
                        <li>Elaborar diversos contratos, así como llevar a cabo aquellas actividades necesarias para garantizar el cumplimiento de dichos contratos.</li>
                        <li>Llevar a cabo encuestas de satisfacción de los servicios facilitados o contratados.</li>
                        <li>Implementar medidas de vigilancia que permitan garantizar la seguridad de los clientes y visitantes durante su estadía en el inmueble de La Empresa.</li>
                        <li>Dar cumplimiento a las obligaciones que adquiere La Empresa, por las diversas disposiciones normativas a nivel Federal, Local y Municipal. </li>
                    </ul>
                    <p><strong>2.2 FINALIDADES DISTINTAS A LAS QUE SE ORIGINAN CON LOS SERVICIOS PROPORCIONADOS POR LA EMPRESA (finalidades secundarias) </strong></p>
                    <p><strong>Finalidades con propósitos de mercadotecnia, publicidad o prospección comercial</strong></p>
                    <ul>
                        <li>Enviar publicidad y actualizaciones de los servicios proporcionados por La Empresa.</li>
                        <li>Registrar a los interesados en los distintos sorteos y trivias celebrados por La Empresa, así como contactar a los ganadores de los mismos.</li>
                        <li>Ofertar promociones de los servicios proporcionados por La Empresa.</li>
                    </ul>

                    <p className="text-decoration-underline">Para llevar a cabo nuestras finalidades con propósitos de mercadotecnia, publicidad o prospección comercial, empleamos canales de contacto directo (correo electrónico y teléfono) así como nuestros canales en redes sociales.</p>

                    <p>En este sentido, si usted desea manifestar su negativa para que La Empresa no emplee sus datos personales para las finalidades que son distintas a las que se originan con los servicios proporcionados por la empresa (finalidades secundarias), usted podrá ejercer su derecho de Oposición para el tratamiento de sus datos personales o bien, Revocar su consentimiento en caso de haber sido proporcionado previamente; para esto, deberá emplear el mecanismo previsto en el apartado denominado "Medios y procedimiento para el ejercicio de los derechos ARCO" de este Aviso de Privacidad.</p>
                    
                    <p><strong>3. TRANSFERENCIAS REALIZADAS POR LA EMPRESA </strong></p>
                    <p>Hacemos del conocimiento de nuestros clientes y visitantes, que únicamente transferimos datos personales en los siguientes casos:</p>

                    <ul>
                        <li>Por disposición de autoridad competente. - En este caso, transferiremos sus datos personales a la autoridad competente, previa instrucción por escrito.</li>
                        <li>Para llevar a cabo trámites frente a Notario Público. - Cuando usted contrata o compra algunos de nuestros servicios para adquirir bienes inmuebles; es necesario que llevemos a cabo trámites ante Notario Público. En este sentido, transferiremos sus datos personales a la Notaría Pública No.100 de León, Guanajuato.</li>
                    </ul>
                    <p>Es importante aclarar que cuando usted proporciona sus datos a otras empresas, de manera previa al primer contacto con Terrazas del Molino; éstas son las Responsables sobre el uso que le dan a sus datos; por lo que La Empresa limita su responsabilidad a aquellos servicios que son inherentes a las finalidades descritas en el apartado 2 de este Aviso de Privacidad, así como a las transferencias mencionadas en este apartado.</p>
                    <p><strong>4. MEDIOS Y PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS ARCO Y LA REVOCACIÓN DEL CONSENTIMIENTO </strong></p>

                    <p>De conformidad con la LFPDPPP usted tiene derecho a Acceder a los datos personales que obran en poder de La Empresa; a Rectificarlos cuando estos sean inexactos o incompletos; a Cancelarlos u Oponerse en todo momento a que los mismos sean usados por La Empresa para llevar a cabo las finalidades previstas en el apartado 2.2 de este Aviso de Privacidad (Derechos ARCO); o en su defecto, Revocar su consentimiento para el uso de los mismos.</p>
                    <p>Para ello, usted deberá enviar una solicitud a nuestro Oficial de Privacidad a través del correo electrónico (<a href="mailto:datospersonales@dmidesarrollos.com">datospersonales@dmidesarrollos.com</a>) indicando los siguientes puntos:</p>
                    <ul><li>Su deseo de Acceder, Rectificar, Oponerse, Cancelar o Revocar su consentimiento para el uso de sus datos personales;</li>
                    <li>Describir de forma clara y precisa, los datos personales sobre los que busca ejercer alguno de los derechos antes mencionados, e;</li>
                    <li>Indicarnos su nombre y domicilio u otro medio para comunicarle nuestra respuesta.</li></ul>
                    <p>Por disposición de la Ley, también deberá adjuntar a su solicitud un documento que acredite su identidad o en su caso, su representación legal y sólo en caso de Rectificación, acompañar un documento que nos permita identificar el o los datos personales a corregirse.</p>
                    <p>A partir del momento del envío de su solicitud, le informamos que los plazos para atender dicha solicitud serán los siguientes:</p>
                    <ul><li>En caso de que la información proporcionada en su solicitud sea insuficiente o errónea para atenderla o bien, no se acompañen los documentos previamente referidos, La Empresa le requerirá que aporte los elementos o documentos necesarios para dar trámite a la misma dentro de los siguientes 5 (cinco) días hábiles; en este caso, usted contará con 10 (diez) días hábiles para atender el requerimiento de información. En caso de no atender este requerimiento, su solicitud se tomará por no presentada.</li>
                    <li>De no requerirle información adicional, La Empresa cuenta con un plazo de 20 (veinte) días hábiles para comunicarle la determinación adoptada a efecto de que, si resulta procedente, su solicitud se haga efectiva dentro de los 15 (quince) días hábiles posteriores a la fecha en la que se comunica la respuesta de la determinación.</li></ul>
                    <p className="text-decoration-underline"><strong>Información sobre el oficial de privacidad y cómo contactarlo.</strong></p>
                    <p>Nuestra oficial de privacidad es la persona al interior de La Empresa que ha sido formalmente designada para fomentar y velar por la protección de los datos personales que tenemos en nuestra posesión, así como atender los derechos de los titulares de los datos personales.</p>
                    <p>Para todo lo relacionado con el tratamiento y protección de sus datos personales, usted podrá contactar a nuestro oficial de privacidad en el correo previamente citado en este rubro.</p>
                    <p><strong>5. OPCIONES Y MEDIOS QUE EL RESPONSABLE OFRECE AL TITULAR PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.</strong></p>
                    <p>En apego a las disposiciones previstas en la LFPDPPP, La Empresa cuenta con medios internos para limitar el uso y divulgación de sus datos personales, así como con medidas de seguridad administrativas, físicas y tecnológicas para garantizar un correcto tratamiento de sus datos personales.</p>
                    <p><strong>6. USO DE MECANISMOS EN MEDIOS REMOTOS O LOCALES DE COMUNICACIÓN ELECTRÓNICA QUE PERMITEN RECABAR DATOS PERSONALES DE MANERA AUTOMÁTICA.</strong></p>
                    <p>La Empresa cuenta con el siguiente sitio de Internet <a href="http://terrazasdelmolino.com/">http://terrazasdelmolino.com/</a>  a través del cual recabamos datos personales, utilizando mecanismos remotos de comunicación electrónica que nos permiten obtener sus datos de forma automatizada, estos mecanismos son denominados "cookies". En caso de requerir información para deshabilitar las cookies de nuestro sitio de Internet, por favor comuníquese con nuestro Oficial de Privacidad a través del correo electrónico especificado en el apartado "Medios y procedimiento para el ejercicio de los derechos ARCO" y refiriendo como asunto del correo electrónico "Información para deshabilitar cookies del portal de Internet".</p>

                    <p><strong>7. PROCEDIMIENTOS Y MEDIOS A TRAVÉS DE LOS CUALES COMUNICAREMOS A LOS TITULARES LOS CAMBIOS AL AVISO DE PRIVACIDAD.</strong></p>
                    <p>En caso de modificaciones a nuestro aviso de privacidad, las mismas se harán de su conocimiento a través de nuestro portal de Internet <a href="http://terrazasdelmolino.com/">http://terrazasdelmolino.com/</a> en la sección "Aviso de Privacidad" y en seguida, ubicando el Aviso de Privacidad Integral para Clientes y Visitantes.</p>
                </div>
            </div>
        </div>
        <BottomFooter/>
    </>
  )
}

export default AvisoPrivacidad