import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class CarouselSlick extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        nextArrow: this.props.next,
        prevArrow: this.props.prev
    };

    return (
        <div className='min-vh-100 position-relative w-100'>
            <div className="position-absolute top-50 start-50 translate-middle w-100 ">
                <div>
                    <Slider {...settings}>
                    {
                        this.props.data.map((e,i) => {
                            return (
                                <div key={i}>
                                    <img loading='lazy' src={e} alt="image carousel tdm" className='w-100 vh-50 object-fit-cover' />
                                </div>
                            )
                        })
                    }
                    </Slider>
                </div>
            </div>
        </div>
    );
  }
}