import React, { useState } from 'react'

function Tipologia() {
    const data = [
        {
            id: 1,
            title: 'Planta Tipo "PH MIRADOR"',
            text: '145 - 165 mts',
            type: 'frente'
        },
        {
            id: 2,
            title: 'Planta Tipo "PARK VIEW"',
            text: '300 - 400 mts',
            type: 'frente'
        },
        {
            id: 3,
            title: 'Planta Tipo "PG MIRADOR"',
            text: '300 - 500 mts',
            type: 'frente'
        },
        {
            id: 4,
            title: 'Planta Tipo "PH MIRADOR"',
            text: '300 - 400 mts',
            type: 'reversa'
        },
        {
            id: 5,
            title: 'Planta Tipo "PG MIRADOR"',
            text: '300 - 500 mts',
            type: 'reversa'
        },
        {
            id: 6,
            title: 'Plata tipo "Fairway High"',
            text: '120 - 145 -165 - 200 mts',
            type: 'reversa'
        },
        {
            id: 7,
            title: 'Planta tipo "Concierge"',
            text: '250 - 500 mts',
            type: 'reversa'
        },
        {
            id: 8,
            title: 'Planta tipo "Fairway"',
            text: '120 - 145 - 165 mts',
            type: 'reversa'
        }
    ]
    
    const [currentTipologia, setCurrentTipologia] = useState('frente');

    const [tipologias, setTipologias] = useState({
        activeTipologia1: false,
        activeTipologia2: false,
        activeTipologia3: false,
        activeTipologia4: false,
        activeTipologia5: false,
        activeTipologia6: false,
        activeTipologia7: false,
        activeTipologia8: false
    });

    const activarTipologia = (tipologiaKey) => {
        setTipologias({
            activeTipologia1: tipologiaKey === 'activeTipologia1',
            activeTipologia2: tipologiaKey === 'activeTipologia2',
            activeTipologia3: tipologiaKey === 'activeTipologia3',
            activeTipologia4: tipologiaKey === 'activeTipologia4',
            activeTipologia5: tipologiaKey === 'activeTipologia5',
            activeTipologia6: tipologiaKey === 'activeTipologia6',
            activeTipologia7: tipologiaKey === 'activeTipologia7',
            activeTipologia8: tipologiaKey === 'activeTipologia8',
        });
    };

    const changeTipologia = e => {
        setCurrentTipologia(e)
    }

  return (
    <>
        <div className='tipologia-top top-0 start-0 d-lg-none'>
            <div className='d-flex align-items-center justify-content-center'>
                <h1 className='display-1 fw-bold text-gold'>96</h1>
                <h3 className='fw-bold ms-3'>RESIDENCIAS <br /> DE LUJO</h3>
            </div>
        </div>
        <div className='tipologia-top top-0 end-0 d-lg-none d-flex align-items-center justify-content-center'>
            <div>
                <button className={`border-0 p-0 bg-transarent ${'frente' === currentTipologia ? 'd-block' : 'd-none'}`} onClick={() => changeTipologia('reversa')}>
                    <img className='img_tipologia' src="images/tipologia/frente.png" alt="" />
                </button>
                <button className={`border-0 p-0 bg-transarent ${'reversa' === currentTipologia ? 'd-block' : 'd-none'}`} onClick={() => changeTipologia('frente')}>
                    <img className='img_tipologia' src="images/tipologia/reversa.png" alt="" />
                </button>
            </div>
        </div>
        <div className='position-relative third-sect-home'>
            <div className='tipologia-top top-0 start-0 d-flex align-items-center d-none d-lg-block'>
                <div className='d-flex align-items-center mt-5'>
                    <h1 className='display-1 fw-bold text-gold'>96</h1>
                    <h3 className='fw-bold ms-3'>RESIDENCIAS <br /> DE LUJO</h3>
                </div>
            </div>
            <div className='tipologia-top top-0 end-0 z-10 d-none d-lg-block'>
                <div>
                    <button className={`border-0 p-0 bg-transarent ${'frente' === currentTipologia ? 'd-block' : 'd-none'}`} onClick={() => changeTipologia('reversa')}>
                        <img className='img_tipologia' src="images/tipologia/frente.png" alt="" />
                    </button>
                    <button className={`border-0 p-0 bg-transarent ${'reversa' === currentTipologia ? 'd-block' : 'd-none'}`} onClick={() => changeTipologia('frente')}>
                        <img className='img_tipologia' src="images/tipologia/reversa.png" alt="" />
                    </button>
                </div>
            </div>
            <div className={`margin-top margin_tipologia ${'frente' === currentTipologia ? 'd-block' : 'd-none'}`}>
                <img className='w-100' src="images/tipologia/vista-frontal.jpg" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia1 ? 'd-block' : 'd-none'}`} src="images/tipologia/mirador.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia2 ? 'd-block' : 'd-none'}`} src="images/tipologia/interiores.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia3 ? 'd-block' : 'd-none'}`} src="images/tipologia/mirador-2.png" alt="" />
            </div>
            <div className={`margin_tipologia ${'reversa' === currentTipologia ? 'd-block' : 'd-none'}`}>
                <img className='w-100' src="images/tipologia/vista-posterior.jpg" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia4 ? 'd-block' : 'd-none'}`} src="images/tipologia/mirador-3.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia6 ? 'd-block' : 'd-none'}`} src="images/tipologia/panoramicos.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia5 ? 'd-block' : 'd-none'}`} src="images/tipologia/mirador-4.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia7 ? 'd-block' : 'd-none'}`} src="images/tipologia/concierge.png" alt="" />
                <img className={`position-absolute top-0 start-0 w-100 margin_tipologia ${tipologias.activeTipologia8 ? 'd-block' : 'd-none'}`} src="images/tipologia/fairway.png" alt="" />
            </div>
            <div>
                <div className="bulelts">
                    <div class="bullets-frontal-residencial" >
                        {
                            data.map((e,i) => {
                                return(
                                <div key={e.id} class={`bullet-click bullet-${e.id} ${e.type === currentTipologia ? 'd-block' : 'd-none'}`} onMouseEnter={() => activarTipologia(`activeTipologia${e.id}`)}>
                                    <div className={`info-depa ${tipologias[`activeTipologia${e.id}`] ? 'd-block' : 'd-none'}`}>
                                        <a className='text-decoration-none gotham-light text-light' href="javascript:;" >
                                            <div class="title-f-d"><p className='gotham-light'>{e.title}</p></div>
                                            <div class="txt-depa"><p className='gotham-light'>{e.text}<sup>2</sup></p></div>
                                        </a>
                                        <div class="depto-360-click">
                                            <a class="fancy-box-360" href="/tour360" target="_blank">
                                                <img src="images/tipologia/depas-360-icon.svg" class="depas-360-icon"/>
                                                <p>Conoce la vista</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </div>
            <div class="position-absolute end-0 bottom-0 etiqueta-tipologia mb-4">
				<p className='text-gold text-uppercase fw-bold mt-2 ps-2'>Tipología Torre {`${'frente' === currentTipologia ? 'frontal' : 'posterior'}`}</p>
			</div>
        </div>
    </>
  )
}

export default Tipologia