import React from 'react'
import TopNavBar from '../components/TopNavBar'
import BottomFooter from '../components/BottomFooter'
import ImgData from '../data/images.json'
import CarouselCustom from '../components/CarouselCustom'
import { Carousel } from 'react-bootstrap'
import Formulario from '../components/Formulario'

function Desarrollador() {
  return (
    <>
      <TopNavBar/>
      <Formulario/>
      <div className="container-fluid m-85px bg-dark pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <h1 className='text-gold fw-bold'>DESARROLLADOR</h1>
              <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" className='position-absolute end-0 d-none d-md-block' style={{bottom:"-80px"}} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-lg-6 m-auto text-center">
            <img loading='lazy' width={300} src="images/desarrollador/GrupoDMI.png" alt="lines" className='img-fluid mb-4'/>
            <p className='text-justify '>Con más de 28 años de experiencia en el sector inmobiliario, Grupo DMI ha brindado vida a proyectos de renombre internacional, reconocidos por su arquitectura avanzada y sostenible, la cual se integra de manera armoniosa con el entorno, y entre estos proyectos se destaca Terrazas del Molino.</p>
            <p className='text-justify'>Guiando a equipos multidisciplinarios especializados en la creación, promoción y operación de proyectos urbanos innovadores, Grupo DMI destaca por su capacidad de generar un impacto transformador que amplía el valor de las áreas circundantes. Esto se traduce en la creación de hitos emblemáticos en múltiples ciudades, consolidando aún más su posición como un referente. </p>
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center flex-column p-4">
            <Carousel>
                {
                    ImgData.galeriaDesarrollador.map((e,i) => {
                        return (
                            <Carousel.Item key={i}>
                              <div className='position-relative'>
                                <div className='position-absolute bottom-0 start-0 py-1 bg-dark bg-opacity-75 text-light w-100 mb-5 text-center fw-bold'>
                                  <p className='m-0'>{e.place}</p>
                                  <p className='m-0'>{e.city}</p>
                                </div>
                                <img loading='lazy' src={e.img} alt="image carousel tdm" className='w-100 h-100 object-fit-cover' />
                              </div>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="row">
          <div className="col-12 col-lg-6 m-auto">
            <img loading='lazy' src="images/desarrollador/Premio.png" alt="premio tdm" className='img-fluid '/>
          </div>
          <div className="col-12 col-lg-6 m-auto px-lg-5">
            <h1 className='fw-bold mb-4 text-gold fw-bold'>PREMIO ADI 2022</h1>
            <p className='text-justify'>El Premio ADI busca reconocer a los proyectos que destacan por su impacto en el entorno y por impulsar las mejores prácticas en el sector inmobiliario.</p>
            <p>Los aspectos que se califican en este importante concurso son:</p>
            <ul>
              <li className='mb-2'>Los criterios de integración de concepto.</li>
              <li className='mb-2'>La planeación del negocio.</li>
              <li className='mb-2'>El éxito económico.</li>
              <li className='mb-2'>El diseño arquitectónico.</li>
              <li className='mb-2'>La aportación a la ciudad y su repercusión al entorno.</li>
            </ul>
            <div className='text-center text-lg-start'>
              <img className='img-fluid' width={180} src="images/desarrollador/LogoADI.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Desarrollador