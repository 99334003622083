import React from 'react'
import BottomFooter from '../components/BottomFooter'
import TopNavBar from '../components/TopNavBar'

function Gracias() {
  return (
    <>
        <TopNavBar/>
        <div className=' container-fluid bg-gracias bg-cover '>
          <div className='row min-vh-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50'>
              <div className='text-center m-85px text-light'>
                  <h1 className='display-4 mb-3'>MENSAJE ENVIADO</h1> 
                  <h5>HEMOS RECIBIDO TU INFORMACIÓN</h5>
                  <h5>¡NOS PONDREMOS EN CONTACTO CONTIGO!</h5>
                  <a href="brochure" className='btn btn-outline-light mt-2' >Descargar Brochure</a>
              </div>
          </div>
        </div>
        <BottomFooter/>
    </>
  )
}

export default Gracias