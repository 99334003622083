import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './style.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Amenidades from './pages/Amenidades';
import Contacto from './pages/Contacto';
import Departamentos from './pages/Departamentos';
import Desarrollador from './pages/Desarrollador';
import Home from './pages/Home';
import Nopage from './pages/Nopage';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import Gracias from './pages/Gracias';
import Tour360 from './pages/Tour360';
import Flipbook from './pages/Flipbook';
import Descarga from './pages/Descarga';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/amenidades" element={<Amenidades />} />
        <Route path="/departamentos" element={<Departamentos/>} />
        <Route path="/desarrollador" element={<Desarrollador/>} />
        <Route path="/contacto" element={<Contacto/>} />
        <Route path="/tour360" element={<Tour360/>} />
        <Route path="/brochure" element={<Flipbook/>} />
        <Route path="/gracias" element={<Gracias/>} />
        <Route path="/aviso-privacidad" element={<AvisoPrivacidad/>} />
        <Route path="*" element={<Nopage/>} />
        <Route path="/Descarga" element={<Descarga/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
