import React from 'react'
import TopNavBar from '../components/TopNavBar'
import CarouselCustom from '../components/CarouselCustom'
import ImgData from '../data/images.json'
import BottomFooter from '../components/BottomFooter'
import CarouselSlick from '../components/CarouselSlick'
import { ReactSVG } from 'react-svg'
import { Carousel } from 'react-bootstrap'
import Formulario from '../components/Formulario'
import Tipologia from './Tipologia'

function Home() {
  return (
    <>
      <TopNavBar/>
      <Formulario/>
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row min-vh-header m-85px position-realtive bg-home bg-cover position-relative">
          <video className='object-fit-cover w-100 h-100 ocultar_movil' loop autoPlay muted>
            <source src="video/VistaPrincipal_TDM_1920x1080px.mp4" type="video/mp4"/>
          </video>
          <div className="col-12 text-center position-absolute top-0">
            <img loading='lazy' src="images/all/Logo_TDM_white.png" alt="image logo" height={200} />
          </div>
          <div className="col-12 text-center position-absolute bottom-0">
            <img loading='lazy' className="mb-4" src="images/all/Flecha.png" alt="image logo" height={30} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 m-auto">
            <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" height={230} />
            <div className='px-2 mb-5 mt-2'>
              {/* <h1 className='text-gold fw-bold'>PROYECTO</h1> */}
              <h2 className='text-gold'>LA ESENCIA DEL PROYECTO</h2>
              <p className='text-justify'>Descubre Terrazas del Molino: un espacio de distinción donde la naturaleza se funde con el lujo. Con  vistas panorámicas que abrazan el Campo de Golf, la apacible presa El Palote y el pulso vibrante de la ciudad, este lugar redefine el significado de exclusividad. Adéntrate en un entorno que refleja tus deseos más elevados, donde la elegancia y la comodidad dan forma a tu nuevo hogar en uno de los desarrollos más selectos de León, Gto.</p>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-auto mb-5">
            <CarouselCustom data={ImgData.carousel1}/>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-tdm bg-cover">
        <div className="row py-5">
          <div className="col-12 text-center text-light py-5">
            <h1 className='fw-bold my-5'>DONDE LA VIDA ES SIMPLEMENTE EXTRAORDINARIA</h1>
            <div><a href="/Descarga" class="btn btn-lg active" role="button" aria-pressed="true">Descargar Brochure</a></div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-gold">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 pb-5 p-lg-5 order-2 order-lg-1">
              <img loading='lazy' src="images/home/MapaUbicacion.png" className='img-fluid' alt="mapa terrazas del molino" />
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center pt-5 pb-4 pt-lg-4 order-1 order-lg-2">
              <div className='p-lg-5'>
                <h1 className='fw-bold text-light'>UN ESTILO DE VIDA INIGUALABLE</h1>
                <p className='text-justify text-light'>Dentro de  El Molino Residencial & Golf, se alza Terrazas del Molino: un desarrollo  vertical de 96 exclusivos departamentos que ofrecen vistas panorámicas de la maravillosa ciudad de León. Gracias a su cercanía a la presa El Palote, y el maravilloso campo de golf, este espacio promete una perfecta armonía entre la naturaleza y la elegancia. <br/>
                Bienvenido a Terrazas del Molino, donde cada amanecer es una promesa y cada anochecer es un abrazo de tranquilidad.</p>
              </div>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-12 col-lg-6 m-auto">
              <div className='px-2'>
                <h1 className='text-light fw-bold'>LEÓN, EL DESTINO IDEAL</h1>
                <p className='text-justify text-light'>Terrazas del Molino se encuentra ubicado dentro de El Molino Residencial & Golf, el desarrollo más prestigioso de la ciudad de León, donde la elegancia y la exclusividad convergen para ofrecer un estilo de vida sin comparación.</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 m-auto">
              <CarouselCustom data={ImgData.carousel2}/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-lg-6 overflow-hidden slider-container ">
            <div className='min-vh-100 position-relative w-100'>
              <div className="position-absolute top-50 start-50 translate-middle w-100 z-1">
                <CarouselSlick 
                  data={ImgData.carousel3}
                  next={<img loading='lazy'
                    src="images/all/Flecha_abajo_gold.png"
                    alt="Prev"
                    className="carousel-arrow prev"
                  />}
                  prev={
                    <img loading='lazy'
                      src="images/all/Flecha_arriba_gold.png"
                      alt="Prev"
                      className="carousel-arrow prev"
                    />
                  }
                />
              </div>
              <div className="position-absolute top-0 start-0 h-100 w-100"></div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column">
            <img loading='lazy' className='ms-auto' src="images/all/Linea_dorada.png" alt="lines" height={150} />
            <div className='d-flex justify-content-center flex-column px-lg-5'>
              <h1 className='text-gold fw-bold'>CARACTERÍSTICAS DEL PROYECTO</h1>
              <ul>
                <li className='mt-2'>96 Residencias con amplias terrazas.</li>
                <li className='mt-2'>Departamentos desde 120 m² hasta 350 m².</li>
                <li className='mt-2'>17 Niveles, 10 en Torre, 7 en Fairway.</li>
                <li className='mt-2'>Equipamiento y acabados de lujo.</li>
                <li className='mt-2'>Amenidades exclusivas.</li>
                <li className='mt-2'>Estacionamiento subterráneo y para visitas.</li>
                <li className='mt-2'>2 núcleos de elevadores con 2 elevadores. Vigilancia 24 horas y accesos controlados.</li>
                <li className='mt-2'>Cargadores para coches eléctricos.</li>
                <li className='mt-2'>Motor lobby de acceso.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark py-5 min-vh-100 d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
              <img loading='lazy' className='img-fluid' src="images/home/Imagenes_proyectos.png" alt="lines"  />
            </div>
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center p-5">
              <h1 className='text-light'>DISEÑO <br /> ARQUITECTÓNICO</h1>
              <p className='text-light text-justify mt-3'>El diseño de Terrazas del Molino es una creación de la prestigiada firma Andrés Escobar Arquitectos, la cual ha desarrollado un sinnúmero de exitosos y vanguardistas proyectos residenciales en México, integrando siempre como objetivo primordial la naturaleza y su entorno.</p>
              <img loading='lazy' className='img-fluid m-auto' width={320} src="images/home/LogoAndresEscobar.png" alt="lines"  />
              
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 px-0 overflow-hidden">
        <div className="row">
          <div className="col-12">
            <Tipologia/>
          </div>
          {/* <div className="col-12 text-center">
            <h1 className='text-gold fw-bold mb-5'>TIPOLOGÍA TORRE POSTERIOR</h1>
            <Carousel>
              <Carousel.Item>
                <div id="svg1">
                  <ReactSVG src="images/home/tipologia1.svg" />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div id="svg2">
                  <ReactSVG src="images/home/tipologia2.svg" />
                </div>
              </Carousel.Item>
            </Carousel>
          </div> */}
        </div>
      </div>
      <div className="container-fluid bg-gold py-5 d-none">
        <div className="container py-5">
          <div className="col-12 col-lg-5 m-auto">
            <h1 className='text-center text-light fw-bold'>!HAZ CLIC PARA EMPEZAR TU RECORRIDO 360°!</h1>
            <p className='text-justify mt-5 text-light'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deserunt explicabo quis deleniti debitis maiores incidunt, eveniet reprehenderit sint expedita quod et sequi sapiente, nostrum asperiores magnam vitae praesentium quidem culpa? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore eos earum harum maxime fuga repellendus alias, sunt dolorum iusto autem iste exercitationem facilis, asperiores facere laudantium consectetur suscipit sit aliquam?</p>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Home