import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'
import { Spinner } from "react-bootstrap";

function Formulario() {
    const [isLoading, setIsLoading] = useState(false);
    const [textErrorForm, setTextErrorForm] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        porque: '',
        medioContacto: '',
        telefono: '',
        ciudad: '',
        pais: '',
        mensaje: '',
        aceptarTerminos: ''
    });

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handlePhoneChange = (value, country, e, formattedValue) => {
        setFormData({
          ...formData,
          telefono: value,
        });
      };

    const handleInputChange= (e) => {
        const { name, value } = e.target;
    
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    const handleSubmit = e => {
        e.preventDefault()
        setIsLoading(true)
        axios.post('https://terrazasdelmolino.com/mail/index.php', formData)
        .then(response => {
          if(response.data.status === '200'){
            setTextErrorForm('')
            window.location.href = '/gracias'
          }else{
            setTextErrorForm('Ocurrió un error al enviar el formulario*')
            setIsLoading(false)
          }
        })
        .catch(error => {
          setTextErrorForm('Ocurrió un error al enviar el formulario*')
          setIsLoading(false)
        })
      }
  return (
    <>
        <a href="/contacto#formulario" className='d-md-none text-light d-flex border-0 bg-gold align-items-center rounded-top py-2 px-3 position-fixed top-50 end-0 translate-middle-y rotate-360 z-3 text-decoration-none'>
            <i class="fas fa-file-signature" aria-hidden="true"></i>
            <h5 className='m-0 ms-2'>Cotiza tu departamento</h5>
        </a>
        <div className={`z-11 d-none d-md-block position-fixed position-formulario ${isActive ? 'active' : ''}`}>
            <button onClick={handleToggle} className='text-light d-flex border-0 bg-gold align-items-center rounded-start py-2 px-3 position-absolute top-0 end-100'>
                <i class="fas fa-file-signature" aria-hidden="true"></i>
                <h5 className='m-0 ms-2 nowrap margin_cotiza'>Cotiza tu departamento</h5>
            </button>
            <form className='w-formulario bg-gold row p-3' onSubmit={handleSubmit}>
                <div className="col-12 col-lg-6 mt-2">
                    <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} className='form-control' placeholder='Nombre*' required minLength={3}/>
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <input type="text" name="apellido" value={formData.apellido} onChange={handleInputChange} className='form-control' placeholder='Apellido*' required minLength={3}/>
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} className='form-control' placeholder='Email*' required />
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <PhoneInput 
                        country={'mx'}
                        inputProps={{
                            name: 'telefono',
                            required: true,
                            minLength: 8,
                        }}
                        value={formData.telefono}
                        onChange={handlePhoneChange}
                    /> 
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <select name="porque" value={formData.porque} onChange={handleInputChange} className='form-select'>
                        <option value="0" selected>Por qué medio se enteró</option>
                        <option value="Youtube" >Youtube</option>
                        <option value="Espectacular" >Espectacular</option>
                        <option value="Revista" >Revistas</option>
                        <option value="Redes sociales" >Redes sociales</option>
                        <option value="Recomendacion" >Recomendación</option>
                        <option value="Busqueda Google" >Búsqueda Google</option>
                    </select>
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <select name="medioContacto" value={formData.medioContacto} onChange={handleInputChange} className='form-select'>
                        <option value="0" selected>Medio para contactarle</option>
                        <option value="Llamada">Llamada</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Correo electrónico">Correo elctrónico</option>
                    </select>
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <input name="ciudad" type="text" value={formData.ciudad} onChange={handleInputChange} className='form-control' placeholder='Ciudad' required/>
                </div>
                <div className="col-12 col-lg-6 mt-2">
                    <input name="pais" type="text" value={formData.pais} onChange={handleInputChange} className='form-control' placeholder='País' required/>
                </div>
                <div className="col-12 mt-2">
                    <textarea name="mensaje" rows="5" className='form-control' placeholder='Mensaje' value={formData.mensaje} required onChange={handleInputChange}></textarea>
                </div>
                <div className="col-12 mt-3 d-flex align-items-center flex-column">
                    <input type="checkbox" name="aceptarTerminos" id="aceptarTerminos" className='form-checkbox' required />
                    <label htmlFor="aviso" className='text-light'>Acepto el <a href="/aviso-privacidad" target='_blank' className='text-light'>Aviso de Privacidad</a></label>
                </div>
                <div className='col-12 mt-3 d-flex justify-content-center'>
                    <button type='submit' className='btn btn-outline-light px-5 border-2 rounded-4' disabled={isLoading}>
                        <span className={isLoading ? 'd-none' : 'd-block'}>Enviar</span> 
                        <Spinner className={isLoading ? 'd-block' : 'd-none'} animation="border" size="sm" variant="light" />
                    </button>
                </div>
                <span className="text-danger mt-2">{textErrorForm}</span>
            </form>
        </div>
    </>

  )
}

export default Formulario