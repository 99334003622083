import React from 'react'
import { Carousel } from 'react-bootstrap'

function CarouselCustom(props) {
  return (
    <Carousel>
        {
            props.data.map((e,i) => {
                return (
                    <Carousel.Item key={i}>
                        <img loading='lazy' src={e} alt="image carousel tdm" className='w-100 h-100 object-fit-cover' />
                    </Carousel.Item>
                )
            })
        }
    </Carousel>
  )
}

export default CarouselCustom