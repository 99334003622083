import React, { useState } from 'react'
import TopNavBar from '../components/TopNavBar'
import BottomFooter from '../components/BottomFooter'
import ImgData from '../data/images.json'
import CarouselCustom from '../components/CarouselCustom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Formulario from '../components/Formulario'

function Departamentos() {
  // const [currentDepartamento, setCurrentDepartamento] = useState(ImgData.departamentosInfo[0])
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
};
  return (
    <>
      <TopNavBar/>
      <Formulario/>
      <div className="container-fluid m-85px bg-dark pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <h1 className='text-gold fw-bold'>DEPARTAMENTOS</h1>
              <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" className='position-absolute end-0 d-none d-md-block' style={{bottom:"-80px"}} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 m-auto">
            <div className='promo_departamentos'></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <h1 className='text-center text-gold fw-bold'>TU ESPACIO A TU MANERA</h1>
            <p className='text-center'>En Terrazas del Molino encontrarás distintos modelos de departamentos de acuerdo a tu estilo de vida. Adicional a la amplitud de sus terrazas e increíbles vistas que serán parte de tu entorno diario en un lugar único y exclusivo.</p>
          </div>
        </div>
        {/* <div className="row py-5">
          <div className="col-12">
            <h3 className='text-gold text-center'>PLANTAS TIPO</h3>
            <div className='border-b-gold d-flex justify-content-around flex-row flex-wrap'>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[0])} className='bg-transparent border-0 text-gold fs-4 py-3'>PARK VIEW</button>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[1])} className='bg-transparent border-0 text-gold fs-4 py-3'>FAIRWAY HIGH</button>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[2])} className='bg-transparent border-0 text-gold fs-4 py-3'>FAIRWAY</button>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[3])} className='bg-transparent border-0 text-gold fs-4 py-3'>CONCIERGE</button>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[4])} className='bg-transparent border-0 text-gold fs-4 py-3'>PENTGARDEN</button>
              <button onClick={() => setCurrentDepartamento(ImgData.departamentosInfo[5])} className='bg-transparent border-0 text-gold fs-4 py-3'>PENTHOUSE</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4 bg-gold p-5">
            <h1 className='text-light' dangerouslySetInnerHTML={{ __html: currentDepartamento.title }}></h1>
            <h5 className='text-dark fw-bold' dangerouslySetInnerHTML={{ __html: currentDepartamento.plantaType }}></h5>
            <h5 className='text-light' dangerouslySetInnerHTML={{ __html: currentDepartamento.plantaType2 }}></h5>
            <table className='mt-4'>
              <tbody>
                {currentDepartamento.data &&
                  currentDepartamento.data.map((e,i) => {
                    return (
                      <tr key={i}>
                        <td className='text-center py-1'><img loading='lazy' src={e.icon} alt="icon tdm" height={60}/></td>
                        <td className='ps-4 fw-bold fs-5' dangerouslySetInnerHTML={{ __html: e.info }}></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="col-12 col-lg-8 d-flex align-items-center p-5">
            <img loading='lazy' src={currentDepartamento.img} alt="" className='img-fluid' />
          </div>
        </div> */}
      </div> 
      <div className="container-fluid my-5">
          <Slider {...settings} className='p-0'>
            
          </Slider>
        <div className="row">
          <div id="carouselExample" class="carousel slide p-0">
            <div class="carousel-inner">
            {
              ImgData.departamentosInfo.map((e,i) => {
                return (
                  <div className={`carousel-item margin_bottom_plano ${i === 0 ? 'active' : ''}`}>
                    <div className='d-flex flex-column flex-lg-row vh-departametos'>
                      <div className="col-12 col-lg-6 bg-gold p-90 ">
                        <div className='overflow-auto h-100'>
                          <h1 className='text-light display-5 gotham-light' dangerouslySetInnerHTML={{ __html: e.title }}></h1>
                          <h5 className='text-dark fw-bold fs-2 ls-4' dangerouslySetInnerHTML={{ __html: e.plantaType }}></h5>
                          <div className=''>
                            <h5 className='text-light mt-5k fs-3 gotham-light ls-4' dangerouslySetInnerHTML={{ __html: e.plantaType2 }}></h5>
                            <table className=''>
                              <tbody>
                                {e.data &&
                                  e.data.map((e,i) => {
                                    return (
                                      <tr key={i}>
                                        <td><img loading='lazy' src={e.icon} alt="icon tdm" height={40}/></td>
                                        <td className='ps-4 fs-14px' dangerouslySetInnerHTML={{ __html: e.info }}></td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 d-flex align-items-center p-5 back_blanco">
                        <img loading='lazy' src={e.img} alt="" className='object-fit-contain w-100 h-100' />
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </div>
            <div className='position-absolute bottom-0 start-50 translate-middle-x'>
              <button class="border border-light border-1 bg-gray p-4" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <i class="fa fa-chevron-left fa-lg text-gold" aria-hidden="true"></i>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="border border-light border-1 bg-gray p-4" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <i class="fa fa-chevron-right fa-lg text-gold" aria-hidden="true"></i>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-0">
            <div className="carousel-vh-100">
              <CarouselCustom data={ImgData.departamentosGaleria1}/>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-8 mx-auto">
            <h1 className='text-gold fw-bold text-center'>VIVE LA VISIÓN</h1>
            <p className='text-center mt-4'>Déjate inspirar por nuestros departamentos muestra: una ventana a la vida que te aguarda en cada rincón de Terrazas del Molino.</p>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12 px-0">
            <div className="carousel-vh-100">
              <CarouselCustom data={ImgData.departamentosGaleria2}/>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-8 mx-auto">
            <h1 className='text-gold fw-bold text-center'>PAISAJES QUE TE TRANSPORTARÁN</h1>
            <p className='text-center mt-4'>Echa un vistazo a lo que te espera, vistas panorámicas que contagian serenidad y emoción.</p>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Departamentos