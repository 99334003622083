import React from 'react'

function Tour360() {
  return (
    <div>
      <iframe
      className='min-vh-100'
        title="Tour Virtual"
        src="/tour-360-terrazas-del-molino/index.htm"
        width="100%"
        frameBorder="0"
      />
    </div>
  )
}

export default Tour360