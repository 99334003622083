import React from 'react'
import CarouselCustom from '../components/CarouselCustom'
import ImgData from '../data/images.json'
import TopNavBar from '../components/TopNavBar'
import BottomFooter from '../components/BottomFooter'
import Formulario from '../components/Formulario'

function Amenidades() {
  return (
    <>
      <TopNavBar/>
      <Formulario/>
      <div className="container-fluid m-85px bg-dark pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <h1 className='text-gold fw-bold'>AMENIDADES</h1>
              <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" className='position-absolute end-0  d-none d-md-block' style={{bottom:"-80px"}} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center p-4 order-2 order-lg-1">
            <CarouselCustom data={ImgData.carouselAmenidades}/>
          </div>
          <div className="col-12 col-lg-6 px-lg-5 order-1 order-lg-2">
            <h1 className='text-gold fw-bold'>DETALLES QUE HACEN LA DIFERENCIA</h1>
            <p className='text-justify'>Terrazas del Molino ofrece múltiples amenidades de esparcimiento para sus residentes dentro y fuera del proyecto, brindando un estilo de vida irrepetible.</p>
            <div className='px-4'>
              <table>
                <tbody>
                  {
                    ImgData.amenidadesIcons.map((e,i) => {
                      return (
                        <tr key={i}>
                          <td className='text-center py-2'><img loading='lazy' src={e.img} alt="icon tdm" className='img-fluid' height={30}/></td>
                          <td className='ps-4'>{e.txt}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Amenidades