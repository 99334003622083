import React from 'react'

function Flipbook() {
  return (
    <div>
      <iframe
      className='min-vh-100'
        title="Tour Virtual"
        src="/flipbook/index.html"
        width="100%"
        frameBorder="0"
      />
    </div>
  )
}

export default Flipbook