import React, { useState } from "react";
import TopNavBar from '../components/TopNavBar'
import BottomFooter from '../components/BottomFooter'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'
import { Spinner } from "react-bootstrap";

function Descarga() {
  const [isLoading, setIsLoading] = useState(false);
  const [textErrorForm, setTextErrorForm] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    email: '',
    porque: '',
    medioContacto: '',
    telefono: '',
    ciudad: '',
    pais: '',
    mensaje: '',
    aceptarTerminos: ''
  });
  const handlePhoneChange = (value, country, e, formattedValue) => {
    setFormData({
      ...formData,
      telefono: value,
    });
  };

const handleInputChange= (e) => {
    const { name, value } = e.target;

    setFormData({
        ...formData,
        [name]: value,
    });
};

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    axios.post('https://terrazasdelmolino.com/mail/index.php', formData)
    .then(response => {
      if(response.data.status === '200'){
        setTextErrorForm('')
        window.location.href = 'https://terrazasdelmolino.com/brochure'
      }else{
        setTextErrorForm('Ocurrió un error al enviar el formulario*')
        setIsLoading(false)
      }
    })
    .catch(error => {
      setTextErrorForm('Ocurrió un error al enviar el formulario*')
      setIsLoading(false)
    })
  }
  return (
    <>
      <TopNavBar/>
      <div className="container-fluid m-85px bg-dark pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <h1 className='text-gold fw-bold'>DESCARGAR BROCHURE</h1>
              <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" className='position-absolute end-0 d-none d-md-block' style={{bottom:"-80px"}} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 bg-gold px-5 py-4 rounded-3">
            <form className='row' id="formulario" onSubmit={handleSubmit}>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" name='nombre' value={formData.nombre} onChange={handleInputChange} className='form-control' placeholder='Nombre*' required />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" name='apellido' value={formData.apellido} onChange={handleInputChange} className='form-control' placeholder='Apellido*' required />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="email" name='email' value={formData.email} onChange={handleInputChange} className='form-control' placeholder='Email*' required />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <PhoneInput 
                  country={'mx'}
                  inputProps={{
                      name: 'telefono',
                      required: true,
                  }}
                  value={formData.telefono}
                  onChange={handlePhoneChange}
                /> 
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <select name="porque" value={formData.porque} onChange={handleInputChange} className='form-select'>
                  <option value="1" selected>Por qué medio se enteró</option>
                  <option value="youtube" >Youtube</option>
                  <option value="espectacular" >Espectacular</option>
                  <option value="revista" >Revistas</option>
                  <option value="redesSociales" >Redes sociales</option>
                  <option value="recomendacion" >Recomendación</option>
                  <option value="busquedaGoogle" >Búsqueda Google</option>
                </select>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <select name="medioContacto" value={formData.medioContacto} onChange={handleInputChange} className='form-select'>
                  <option value="0" selected>Medio para contactarle</option>
                  <option value="llamada">Llamada</option>
                  <option value="whatsapp">Whatsapp</option>
                  <option value="correo">Correo lectrónico</option>
                </select>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" value={formData.ciudad} onChange={handleInputChange} name='ciudad' className='form-control' placeholder='Ciudad' />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" value={formData.pais} onChange={handleInputChange} name='pais' className='form-control' placeholder='País' />
              </div>
              <div className="col-12 mt-2">
                <textarea rows="5" className='form-control' placeholder='Mensaje' name="mensaje" value={formData.mensaje} onChange={handleInputChange}></textarea>
              </div>
              <div className="col-12 mt-3 d-flex align-items-center flex-column">
                <input id="aceptarTerminos" type="checkbox" name='aviso'  className='form-checkbox' />
                <label htmlFor="aviso" className='text-light'>Acepto el <a href="/aviso-privacidad" target='_blank' className='text-light'>Aviso de Privacidad</a></label>
              </div>
              <div className='col-12 mt-3 d-flex justify-content-center'>
                <button type='submit' className='btn btn-outline-light px-5 border-2 rounded-4' disabled={isLoading}>
                    <span className={isLoading ? 'd-none' : 'd-block'}>Descargar</span> 
                    <Spinner className={isLoading ? 'd-block' : 'd-none'} animation="border" size="sm" variant="light" />
                </button>
              </div>
              <span className="text-danger mt-2">{textErrorForm}</span>
            </form>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Descarga