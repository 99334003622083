import React, { useState } from "react";
import TopNavBar from '../components/TopNavBar'
import BottomFooter from '../components/BottomFooter'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'
import { Spinner } from "react-bootstrap";

function Contacto() {
  const [isLoading, setIsLoading] = useState(false);
  const [textErrorForm, setTextErrorForm] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    email: '',
    porque: '',
    medioContacto: '',
    telefono: '',
    ciudad: '',
    pais: '',
    mensaje: '',
    aceptarTerminos: ''
  });
  const handlePhoneChange = (value, country, e, formattedValue) => {
    setFormData({
      ...formData,
      telefono: value,
    });
  };

const handleInputChange= (e) => {
    const { name, value } = e.target;

    setFormData({
        ...formData,
        [name]: value,
    });
};

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    axios.post('https://terrazasdelmolino.com/mail/index.php', formData)
    .then(response => {
      if(response.data.status === '200'){
        setTextErrorForm('')
        window.location.href = '/gracias'
      }else{
        setTextErrorForm('Ocurrió un error al enviar el formulario*')
        setIsLoading(false)
      }
    })
    .catch(error => {
      setTextErrorForm('Ocurrió un error al enviar el formulario*')
      setIsLoading(false)
    })
  }
  return (
    <>
      <TopNavBar/>
      <div className="container-fluid m-85px bg-dark pt-5 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <h1 className='text-gold fw-bold'>AGENDA TU VISITA</h1>
              <img loading='lazy' src="images/all/Linea_dorada.png" alt="lines" className='position-absolute end-0 d-none d-md-block' style={{bottom:"-80px"}} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5 pt-4">
        <div className="row">
          <div className="col-12 col-lg-6 p-4">
            <h1 className='fw-bold text-gold'>CONTACTA A UN ASESOR</h1>
            <p className='my-4 text-justify'>Estamos a su disposición para atender cualquier consulta que pueda surgir. No dude en contactarnos a través de nuestro formulario de contacto.</p>
            <b>Direccion:</b>
            <p className='mb-0'>Paseo El Molino #605</p>
            <p className='mb-0'>El Molino Residencial & Golf</p>
            <p className='mb-0'>Teléfono: (477) 781 1119</p>
            <ul className='list-unstyled mt-5'>
              <li>
                <a href="#" className='text-decoration-none text-dark d-inline-block'><i className="fa-brands fa-square-facebook me-2 text-gold"></i>@TerrazasDelMolino</a>
              </li>
              <li>
                <a href="#" className='text-decoration-none text-dark d-inline-block'><i className="fa-brands fa-instagram me-2 text-gold"></i>@terrazasdelmolino</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 bg-gold px-5 py-4 rounded-3">
            <form className='row' id="formulario" onSubmit={handleSubmit}>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" name='nombre' value={formData.nombre} onChange={handleInputChange} className='form-control' placeholder='Nombre*' required minLength={3}/>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" name='apellido' value={formData.apellido} onChange={handleInputChange} className='form-control' placeholder='Apellido*' required  minLength={3}/>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="email" name='email' value={formData.email} onChange={handleInputChange} className='form-control' placeholder='Email*' required />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <PhoneInput 
                  country={'mx'}
                  inputProps={{
                      name: 'telefono',
                      require: true,
                      minLength: 8,
                  }}
                  value={formData.telefono}
                  onChange={handlePhoneChange}
                /> 
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <select name="porque" value={formData.porque} onChange={handleInputChange} className='form-select'>
                  <option value="1" selected>Por qué medio se enteró</option>
                  <option value="youtube" >Youtube</option>
                  <option value="espectacular" >Espectacular</option>
                  <option value="revista" >Revistas</option>
                  <option value="redesSociales" >Redes sociales</option>
                  <option value="recomendacion" >Recomendación</option>
                  <option value="busquedaGoogle" >Búsqueda Google</option>
                </select>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <select name="medioContacto" value={formData.medioContacto} onChange={handleInputChange} className='form-select'>
                  <option value="0" selected>Medio para contactarle</option>
                  <option value="llamada">Llamada</option>
                  <option value="whatsapp">Whatsapp</option>
                  <option value="correo">Correo lectrónico</option>
                </select>
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" value={formData.ciudad} onChange={handleInputChange} name='ciudad' className='form-control' placeholder='Ciudad' required minLength={3} />
              </div>
              <div className="col-12 col-lg-6 mt-2">
                <input type="text" value={formData.pais} onChange={handleInputChange} name='pais' className='form-control' placeholder='País'required minLength={3} />
              </div>
              <div className="col-12 mt-2">
                <textarea rows="5" className='form-control' placeholder='Mensaje' name="mensaje" value={formData.mensaje} onChange={handleInputChange}required minLength={3}></textarea>
              </div>
              <div className="col-12 mt-3 d-flex align-items-center flex-column">
                <input id="aceptarTerminos" type="checkbox" name='aviso'  className='form-checkbox' required />
                <label htmlFor="aviso" className='text-light'>Acepto el <a href="/aviso-privacidad" target='_blank' className='text-light'>Aviso de Privacidad</a></label>
              </div>
              <div className='col-12 mt-3 d-flex justify-content-center'>
                <button type='submit' className='btn btn-outline-light px-5 border-2 rounded-4' disabled={isLoading}>
                    <span className={isLoading ? 'd-none' : 'd-block'}>Enviar</span> 
                    <Spinner className={isLoading ? 'd-block' : 'd-none'} animation="border" size="sm" variant="light" />
                </button>
              </div>
              <span className="text-danger mt-2">{textErrorForm}</span>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 bg-gold">
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative">
              <div className='contact-div  rounded-5 text-light p-3 ms-lg-5'>
                <h5 className='fw-bold text-uppercase'>Encuéntranos en</h5>
                <p className='mb-0'>Paseo El Molino #605</p>
                <p>El Molino Residencial & Holf.</p>
                <h5 className='fw-bold'>HORARIO:</h5>
                <p className='mb-0'>Lunes a Viernes de 9:00 a 20:00 h</p>
                <p className='mb-0'>Sábado de 9:00 a 19:00 h</p>
                <p className='mb-0'>Domingos y días festivos de 11:00 a 19:00 h</p>
              </div>
              <iframe className='min-vh-header' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1904643.7281258265!2d-103.93339693750002!3d21.191869699999987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bb9f84343a145%3A0x5337771186229fdf!2sTerrazas%20del%20Molino!5e0!3m2!1ses!2smx!4v1702271398902!5m2!1ses!2smx" width="100%"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter/>
    </>
  )
}

export default Contacto